/**
 * This code was generated by Builder.io.
 */
import React from "react";

const AboutSection = () => (
  <section className="about-section">
    <div className="container">
      <h1 className="about-section-title">About Us</h1>
      <p className="section-description">
        Orbit Financial Services Limited (OFSL) was founded with the mission to
        empower Nigerian small businesses by providing access to funds through
        innovative and customer-centric financial solutions.
      </p>
    </div>
  </section>
);

export default AboutSection;
