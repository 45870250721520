/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Hero from "./Hero";
import FAQTabs from "./FAQTabs";
import FAQList from "./FAQList";
import ContactCTA from "./ContactCTA";
import '../styles/globals.css'
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const FAQPage = () => (
  <div className="faq-page">
    <Header />
    <main>
      <Hero />
      <section className="faq-section">
        <FAQTabs />
        {/*<FAQList />*/}
      </section>
      <ContactCTA />
    </main>
    <Footer />
  </div>
);

export default FAQPage;
