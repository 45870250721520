/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";

const FAQItem = ({ question, answer, list, showlist }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <button
        className="faq-question"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        {question}
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b3b1c482a10f3f4398bcddd1caeb1f12fd27dc54fcb4bf87b18d614ab217dee0?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892"
          alt={isOpen ? "Collapse" : "Expand"}
          className={`faq-toggle ${isOpen ? "open" : ""}`}
        />
      </button>
      {isOpen && (
        <div className="faq-answer">
          <p>{answer}</p>
          <ul>
            {list?.map((lst, index) => (
              <li key={index}>{lst}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FAQItem;
