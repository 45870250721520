/**
 * This code was generated by Builder.io.
 */
import React from "react";

const ValueItem = ({ number, title, description }) => (
  <div className="value-item">
    <div className="value-number">{number}</div>
    <div className="value-content">
      <h3 className="value-title">{title}</h3>
      <p className="value-description">{description}</p>
    </div>
  </div>
);

export default ValueItem;
