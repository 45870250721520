/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import BlogPost from "../components/blogpost/BlogPost";
import instance from "../../axiosConfig";

const BlogSection = () => {
  const [data, setData] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    instance
      .get("")
      .then((resp) => {
        console.log("data", resp.data);
        setData(resp.data);
      })
      .catch((error) => {
        setError(true);
      });
  }, []);
  const blogPosts = [
    {
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/bae088d9a9e0552e46e69bc76af63a1f01458f4a2ac814101ce0b559eb593f12?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&",
      category: "EDUCATION",
      date: "February 26th, 2024",
      link: "post",
      title: "New AI Tools Revolutionize Micro-lending",
      excerpt:
        "AI-powered algorithms analyze vast datasets to assess borrower creditworthiness swiftly and accurately, expanding access to micro-loans",
    },
    {
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/f0a21fcdb6ebcbe24b92f111cce998e40e1224bece4993cd0aa225cd0bfa0ccc?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&",
      category: "EDUCATION",
      date: "March 12th, 2024",
      link: "post",
      title: "How Green Financing is Changing the Nigerian Market",
      excerpt:
        "Green financing initiatives in Nigeria aim to boost investments in renewable energy sources like solar and wind power.",
    },
    {
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/6c761f90f6db68f87f5b2b092adf0d582ed33744ce41394e0123f3c1fbd387c3?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&",
      category: "EDUCATION",
      date: "April 10th, 2024",
      link: "post",
      title: "How to have Sustainable Investment in Nigeria Market Landscape",
      excerpt:
        "Sustainable investment in Nigeria is becoming increasingly relevant as both local and international investors",
    },
    {
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/e141eeccb7ab1aac2641457711d19b692f97b4e27024b0f9bf2831f46aafde50?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&",
      category: "EDUCATION",
      date: "July 16th, 2024",
      link: "post",
      title: "How Technology has aided in Small and Medium Scale Enterprise.",
      excerpt:
        "Technology has become a crucial enabler for Small and Medium Scale Enterprises (SMEs), driving growth, innovation, and efficiency",
    },
  ];

  return (
    <section className="home-blog-section">
      <div className="home-blog-header">
        <h2 className="home-blog-section-title">Latest Blog</h2>
        <div className="home-blog-divider"></div>
        <h3 className="home-blog-section-subtitle">In the News</h3>
      </div>
      <div className="home-blog-grid">
        {blogPosts.map((post, index) => (
          <BlogPost key={index} {...post} />
        ))}
      </div>
    </section>
  );
};

export default BlogSection;
