/**
 * This code was generated by Builder.io.
 */
import React from "react";

const TeamMember = ({ imageSrc, name, position, description }) => (
  <div className="team-member">
    <img loading="lazy" src={imageSrc} className="member-image" alt={name} />
    <div className="member-info">
      <h3 className="member-name">{name}</h3>
      <p className="member-position">{position}</p>
      <p className="member-description">{description}</p>
    </div>
  </div>
);

export default TeamMember;
