/**
 * This code was generated by Builder.io.
 */
import React from "react";
import logo from "../../images/onLight.png";

const Logo = () => {
  return (
    <div className="logo-container">
      <img
        loading="lazy"
        src={logo}
        alt="Company logo icon"
        className="logo-text"
      />
      {/*<img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/9922471d8b9c4158c8ce5c9064643d3d1f53a993633fa5b7bb6c1996911d2d51?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&"
        alt="Company logo text"
        className="logo-text"
      />*/}
    </div>
  );
};

export default Logo;
