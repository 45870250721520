/**
 * This code was generated by Builder.io.
 */
import React from "react";

const Hero = () => (
  <section className="faq-hero">
    <img
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/330c842d7eb56b640f70b649f0bb541fda276edac6d98f37a9c3759455f0f300?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892"
      alt="Background image"
      className="faq-hero-background"
    />
    <div className="faq-hero-content">
      <h1 className="faq-hero-title">Frequently Asked Questions (FAQ)</h1>
      <p className="faq-hero-description">
        We know you might have a few questions about us. Here is a summary of
        frequently asked questions and answers we have curated just for you.
      </p>
    </div>
  </section>
);

export default Hero;
