/**
 * This code was generated by Builder.io.
 */
import React from "react";
import './testimonial.css'

const Testimonial = ({ rating, content, author }) => {
  return (
    <div className="testimonial">
      <div className="testimonial-rating">
        {[...Array(5)].map((_, i) => (
          <img
            key={i}
            loading="lazy"
            src={
              i < rating
                ? "https://cdn.builder.io/api/v1/image/assets/TEMP/e7d7b90161852878601eb40e09b90bbb280e3244dba9a028ab170b5d84a6de8b?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&"
                : "https://cdn.builder.io/api/v1/image/assets/TEMP/0d4ad546edfb80fa8644f082b298a7da0b5dec035f004515d485996908bab443?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&"
            }
            alt={i < rating ? "Filled star" : "Empty star"}
            className="star-icon"
          />
        ))}
      </div>
      <p className="testimonial-content">{content}</p>
      <p className="testimonial-author">{author}</p>
    </div>
  );
};

export default Testimonial;
