/**
 * This code was generated by Builder.io.
 */
import * as React from "react";

function ContactForm() {
  return (
    <form className="contact-form">
      <div className="form-group">
        <label for="fullName" className="form-label">
          Full name
        </label>
        <input
          type="text"
          id="fullName"
          className="form-input"
          placeholder="Enter First name"
          aria-label="Full name"
        />
      </div>
      <div className="form-group">
        <label for="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          id="email"
          className="form-input"
          placeholder="Enter email address"
          aria-label="Email"
        />
      </div>
      <div className="form-group">
        <label for="phoneNumber" className="form-label">
          Phone number
        </label>
        <input
          type="tel"
          id="phoneNumber"
          className="form-input"
          placeholder="Enter phone number"
          aria-label="Phone number"
        />
      </div>
      <div className="form-group">
        <label for="subject" className="form-label">
          Subject
        </label>
        <textarea
          id="subject"
          className="form-textarea"
          placeholder="Type your message"
          aria-label="Message"
        ></textarea>
      </div>
      <button type="submit" className="primary-button">
        <span className="button-text">Submit</span>
      </button>
      <style jsx>{`
        .contact-form {
          display: flex;
          flex-direction: column;
          gap: 31px;
          width: 50%;
        }
        .form-group {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
        .form-label {
          color: rgba(255, 255, 255, 1);
          font-weight: 500;
        }
        .form-input,
        .form-textarea {
          border-radius: 6px;
          border: 1px solid #b0b0b0;
          background: var(--Shade-White, #fff);
          color: var(
            --Orbit-Color-Style-Slate---Neutral-slate---neutral-500,
            #475569
          );
          padding: 12px;
          font-family: Inter, sans-serif;
        }
        .form-input {
          height: 60px;
        }
        .form-textarea {
          height: 153px;
          padding-top: 8px;
        }
        .primary-button {
          background: var(
            --Orbit-Color-Style-Tiffany-blue-tiffany-blue-500---Main,
            #78d3ce
          );
          padding: 16px 24px;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-feature-settings: "cv01" on, "cv03" on, "cv04" on;
        }
        @media (max-width: 991px) {
          .primary-button {
            padding: 20px 20px;
          }
          .contact-form {
            width: 100%;
          }
        }
      `}</style>
    </form>
  );
}

export default ContactForm;
