/**
 * This code was generated by Builder.io.
 */
import React from "react";
import './services.css'

const OurServices = () => {
  const services = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/78c9e1e1df6c9b93bcad3481405286e6e8942dfd01e17bb61c195422f434ac76?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&",
      title: "Microloans for Small Business",
      description:
        "Providing tailored microloans to help small businesses grow",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/9c1a9914caff35cee61cb27944b327f3d50e4100ea15b5e974e48e9503927684?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&",
      title: "Sustainable Investing",
      description:
        "Offering investment opportunities aligned with environmental and social values.",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b8118220d0f8b4f804fe766f01abf32801db2024acf26b23f15f3c7ca945f18f?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&",
      title: "Invoice Discounting",
      description:
        "Advancing cash against outstanding invoices to improve cash flow.",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a82c93fe767171e8f1a88fcbef409088de697278346866a12169d7a89af67c36?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&",
      title: "Green Financing",
      description: "Financing environmentally sustainable projects.",
    },
  ];

  return (
    <section className="our-services">
      <div className="services-header">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/ada4363d51c5d172b3becbd70f58473eb2f714207efda0b17b18bb839cb5cfd1?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&"
          alt="Decorative element"
          className="services-decoration-1"
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/05956928c7032eec1c7ff709a83d0a8119594c80f44bf9c58dd9833af8f126ce?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&"
          alt="Decorative element"
          className="services-decoration-2"
        />
        <h2 className="services-title">Our Services</h2>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/066aac822879201d432e1675894b5d6b759673aa4b0ffb502ca1415c4a22f51e?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&"
          alt="Decorative element"
          className="services-decoration-3"
        />
      </div>
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-item">
            <img
              loading="lazy"
              src={service.icon}
              alt={service.title}
              className="service-icon"
            />
            <div className="service-content">
              <h3 className="home-service-title">{service.title}</h3>
              <p className="home-service-description">{service.description}</p>
            </div>
            <a href="#" className="service-link">
              Learn more
            </a>
          </div>
        ))}
      </div>
      <div className="services-footer">
        <div className="services-navigation">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/403be7354412ee6cd3226b9f3bf95cf5c8b64aeb8c61963c1daf5d65b0719b04?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&"
            alt="Previous"
            className="nav-arrow prev"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/cbbd46c184dc986bc870d81a4ed7bde5d5fb479699907f8a1d8ff9c3551e1818?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&"
            alt="Next"
            className="nav-arrow next"
          />
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7fd2213cd8ad300ae5476acbe6bc12c6d7602e3807e81ccd1a384152ef7bf22a?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&"
          alt="Decorative element"
          className="services-decoration-4"
        />
      </div>
    </section>
  );
};

export default OurServices;
