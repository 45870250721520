/**
 * This code was generated by Builder.io.
 */
import React from "react";
import Logo from "./Logo";
import Navigation from "./navigation/Navigation";
import Button from "./Button";

const Header = () => {
  return (
    <header className="header">
      <div className="header-container">
        <div className="header-content">
          <a href="/">
            <Logo />
          </a>
          <div className="links-actions">
            <Navigation />
            <div className="actions">
              {/* <Button variant="primary" label="Sign up" /> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
