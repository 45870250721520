/**
 * This code was generated by Builder.io.
 */
import React from "react";
import AboutSection from "./AboutSection";
import StorySection from "./StorySection";
import MissionVisionSection from "./MissionVisionSection";
import TeamSection from "./TeamSection";
import ValuesSection from "./ValuesSection";
import './styles.css'
import Header from "../../components/Header";
import OurStory from "./OurStory";
import Footer from "../../components/Footer";

const AboutPage = () => (
  <div className="about-page">
    <Header />
    <main>
      <AboutSection />
      <StorySection />
      {/*<MissionVisionSection />
      <OurStory />*/}
      <TeamSection />
      <ValuesSection />
    </main>
    <Footer />
  </div>
);

export default AboutPage;
