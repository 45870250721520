/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { useNavigate } from "react-router-dom";

const Button = ({ variant, label, link }) => {
  const navigation = useNavigate()
  return <button onClick={() => navigation(link)} className={`button ${variant}`}>{label}</button>;
};

export default Button;
