/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

const ContactCTA = () => {
  const navigate = useNavigate();
  return (
    <section className="contact-cta">
      <h2 className="cta-title">Still Need Further Assistance</h2>
      <p className="cta-description">Our Team are available to help</p>
      <button
        onClick={() => navigate('/contact')}
        className="cta-button"
      >
        Contact Us
      </button>
    </section>
  );
};

export default ContactCTA;
