/**
 * This code was generated by Builder.io.
 */
import React from "react";
import img from "../../../images/innovationTech.png";

const DigitalExperience = () => {
  const features = [
    {
      number: "1",
      title: "User Friendly Interface",
      description:
        "Ensuring easy navigation and intuitive design for a seamless user experience.",
    },
    {
      number: "2",
      title: "24/7 Access",
      description:
        "Providing round-the-clock access to our digital lending platforms.",
    },
    {
      number: "3",
      title: "Enhance Security",
      description:
        "Implementing robust data protection measures and secure transaction protocols.",
    },
  ];

  return (
    <section className="digital-experience">
      <div className="experience-content">
        <h2 className="section-title">Technology</h2>
        <h3 className="section-subtitle">Seamless Digital Experience</h3>
        {/*<div className="feature-list">
          {features.map((feature, index) => (
            <div key={index} className="feature-item">
              <span className="feature-number">{feature.number}</span>
              <div className="feature-details">
                <h4 className="feature-title">{feature.title}</h4>
                <p className="feature-description">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>*/}
      </div>
      <div className="experience-image">
        <img
          src={img}
          alt="Digital experience illustration"
          className="experience-img"
        />
      </div>
    </section>
  );
};

export default DigitalExperience;
