/**
 * This code was generated by Builder.io.
 */
import React from "react";
import img1 from "../../../images/img1.png";
import img2 from "../../../images/img2.png";
import img3 from "../../../images/img3.png";
import SolutionCard from "./SolutionCard";
import Section from "./TechSection";
import bg from "../../../images/techBg.png";

const TechnologySection = () => {
  const sections = [
    {
      imageSrc: img1,
      imageAlt: "A conceptual image representing personalized loan offers",
      title: "Personalised Loan Offer",
      description:
        "Utilizing customer segmentation and dynamic pricing to offer personalized loan solutions",
    },
    {
      imageSrc: img2,
      imageAlt: "A conceptual image representing automated credit scoring",
      title: "Automated Credit Score",
      description:
        "Using alternative data sources and machine learning models to provide accurate and fair credit scoring",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/85f361f0cd8f89ce32ee4f56d67f0fbdb92aebc893931ba47d34bd06de230fa7?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892",
      imageAlt: "A conceptual image representing fraud detection",
      title: "Fraud Detection",
      description:
        "Implementing real-time monitoring and behavioral analysis to prevent and detect fraudulent activities.",
    },
  ];

  return (
    <>
      <main className="tech-main-container">
        <header className="tech-header-section">
          <h1 className="tech-main-title">Technology</h1>
          <h2 className="tech-subtitle">Ai Driven Solutions</h2>
        </header>
        <div className="tech-sections-wrapper">
          {sections.map((section, index) => (
            <Section
              key={index}
              imageSrc={section.imageSrc}
              imageAlt={section.imageAlt}
              title={section.title}
              description={section.description}
            />
          ))}
        </div>
      </main>
      <style jsx>{`
        .tech-main-container {
          background-image: url(${bg});
          background-size: cover;
          display: flex;
          flex-direction: column;
          padding: 79px 80px;
          font-family: Effra, sans-serif;
        }
        @media (max-width: 991px) {
          .tech-main-container {
            padding: 0 20px;
          }
        }
        .tech-header-section {
          display: flex;
          flex-direction: column;
          color: #fff;
        }
        .tech-main-title {
          font-size: 48px;
          font-weight: 700;
        }
        @media (max-width: 991px) {
          .tech-main-title {
            font-size: 40px;
          }
        }
        .tech-subtitle {
          font-size: 24px;
          margin-top: 16px;
        }
        .tech-sections-wrapper {
          display: flex;
          gap: 10%;
          margin-top: 24px;
          color: #fff;
        }
        @media (max-width: 991px) {
          .tech-sections-wrapper {
            max-width: 100%;
          }
          .tech-sections-wrapper {
            display: flex;
            flex-direction: column;
          }
        }
        .tech-section-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 240px;
          width: 100%;
          max-width: 388px;
          margin: auto 0;
        }
        .tech-section-image {
          width: 100%;
          height: 300px;
          max-width: 388px;
          aspect-ratio: 1.29;
          object-fit: contain;
          object-position: center;
          border-radius: 10px;
        }
        .section-content {
          display: flex;
          flex-direction: column;
          margin-top: 24px;
          min-height: 120px;
          width: 100%;
        }
        .tech-section-title {
          font-size: 24px;
          font-weight: 700;
          line-height: 2;
          color: #fff;
        }
        .tech-section-description {
          margin-top: 8px;
          font-size: 18px;
          line-height: 24px;
          color: #fff;
        }
      `}</style>
    </>
  );
};

export default TechnologySection;
