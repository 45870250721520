/**
 * This code was generated by Builder.io.
 */
import React, { useState } from "react";
import {
  faqData,
  faqList,
  investmentData,
  invoicingData,
  loanRepaymentData,
  microloansData,
  sustainabilityData,
} from "./FAQList";
import FAQItem from "./FAQItem";

const FAQTabs = () => {
  const [activeTab, setActiveTab] = useState("All FAQs");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName); // Set the clicked tab as active
  };

  const allFaq = [
    ...faqData,
    ...microloansData,
    ...investmentData,
    ...investmentData,
    ...loanRepaymentData,
    ...sustainabilityData,
  ];

  const renderContent = () => {
    switch (activeTab) {
      case "All FAQs":
        return (
          <div className="faq-list">
            {allFaq.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                list={faq.list}
              />
            ))}
          </div>
        );
      case "Microloans":
        return (
          <div className="faq-list">
            {microloansData.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                list={faq.list}
              />
            ))}
          </div>
        );
      case "Investment":
        return (
          <div className="faq-list">
            {investmentData.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
              />
            ))}
          </div>
        );
      case "Invoicing":
        return (
          <div className="faq-list">
            {invoicingData.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
              />
            ))}
          </div>
        );
      case "Loan Repayment":
        return (
          <div className="faq-list">
            {loanRepaymentData.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
              />
            ))}
          </div>
        );
      case "Others":
        return (
          <div className="faq-list">
            {sustainabilityData.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                list={faq.list}
              />
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <nav className="faq-tabs">
        <button
          className={`tab-button ${activeTab === "All FAQs" ? "active" : ""}`}
          onClick={() => handleTabClick("All FAQs")}
        >
          All FAQs
        </button>
        <button
          className={`tab-button ${activeTab === "Microloans" ? "active" : ""}`}
          onClick={() => handleTabClick("Microloans")}
        >
          Microloans
        </button>
        {/*<button
          className={`tab-button ${activeTab === "Investment" ? "active" : ""}`}
          onClick={() => handleTabClick("Investment")}
        >
          Investment
        </button>
        <button
          className={`tab-button ${activeTab === "Invoicing" ? "active" : ""}`}
          onClick={() => handleTabClick("Invoicing")}
        >
          Invoicing
        </button>*/}
        <button
          className={`tab-button ${
            activeTab === "Loan Repayment" ? "active" : ""
          }`}
          onClick={() => handleTabClick("Loan Repayment")}
        >
          Loan Repayment
        </button>
        <button
          className={`tab-button ${
            activeTab === "Others" ? "active" : ""
          }`}
          onClick={() => handleTabClick("Others")}
        >
          Others
        </button>
        {/*<button className="tab-button active">All FAQs</button>
      <button className="tab-button">Microloans</button>
      <button className="tab-button">Investment</button>
      <button className="tab-button">Invoicing</button>
      <button className="tab-button">Loan Repayment</button>
      <button className="tab-button">Sustainability</button>*/}
      </nav>

      <div className="tab-content">{renderContent()}</div>
    </>
  );
};

export default FAQTabs;
