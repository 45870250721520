/**
 * This code was generated by Builder.io.
 */
import React from "react";
import { useNavigate } from "react-router-dom";

const BlogPost = ({ image, category, date, title, excerpt, link }) => {
  const navigation = useNavigate();
  return (
    <article className="home-blog-post" onClick={() => navigation(`../${link}`)}>
      <img loading="lazy" src={image} alt={title} className="home-blog-image" />
      <div className="home-blog-content">
        <div className="blog-meta">
          <span className="blog-category">{category}</span>
          <span className="blog-date">{date}</span>
        </div>
        <h3 className="blog-title">{title}</h3>
        <p className="blog-excerpt">{excerpt}</p>
      </div>
    </article>
  );
};

export default BlogPost;
