/**
 * This code was generated by Builder.io.
 */
import React from "react";
import TeamMember from "./TeamMember";

const TeamSection = () => {
  const teamMembers = [
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/7363c4a7f50ec0dd4d07c941e4938dbddf5473c2efe61c301ba60d72babf3bce?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892",
      name: "John Doe",
      position: "Chief Executive Officer (CEO)",
      description:
        "Over 20 years of experience in financial services, passionate about leveraging technology to empower small businesses.",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/4503d48fbcf2a1a227f707c47addfe34fb368efd9c2e35fffc0f3a2386d8b0cf?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892",
      name: "Jane Smith",
      position: "Chief Financial Officer (CFO)",
      description:
        "Expert in financial management and sustainable investing, committed to ethical financial practices.",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/ec919f2f7381a08711c82af208f14dd38c03cfedf00510ffde2e8927927c3e74?placeholderIfAbsent=true&apiKey=0dc91187d1fc4cb3931d3d7b27a4a892",
      name: "Michael Johnson",
      position: "Chief Technological Officer (CTO)",
      description:
        "Specialist in AI and digital platforms, dedicated to creating seamless and secure digital experiences for customers.",
    },
  ];

  return (
    <section className="team-section">
      <div className="container">
        <h2 className="about-section-title">Meet our Key Team Members</h2>
        <div className="team-members">
          {teamMembers.map((member, index) => (
            <TeamMember key={index} {...member} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
