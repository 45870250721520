/**
 * This code was generated by Builder.io.
 */
import React from "react";
import InfoCard from "./InfoCard";
import logo from "../../../images/onLightBackground.png";

const cards = [
  {
    imageSrc: logo,
    title: "Mission",
    description:
      "At Orbit Financial Services Limited, our mission is to empower individuals and businesses by providing innovative and reliable financial solutions. We are dedicated to fostering long-term partnerships with our clients, helping them achieve their financial goals with integrity, transparency, and excellence",
  },
  {
    imageSrc: logo,
    title: "Vision",
    description:
      "Our vision at Orbit Financial Services Limited is to empower individuals and businesses to achieve financial stability and growth through innovative solutions, personalized service, and a commitment to integrity.",
  },
];

const StorySection = () => (
  <section className="story-section">
    <div className="container">
      <div className="story-content">
        <h2 className="story-title">Our Story</h2>
        <p className="story-description">
          Since its inception, OFSL has aimed to bridge the gap left by
          traditional banks, focusing on the underserved market segment of small
          business owners who lack collateral.
        </p>
      </div>
    </div>
    <div className="mission-vision-section">
      <div className="container">
        <div className="card-container">
          {cards.map((card, index) => (
            <InfoCard key={index} {...card} />
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default StorySection;
