/**
 * This code was generated by Builder.io.
 */
import React, { useEffect, useState } from "react";
import Button from "../Button";
import "./hero.css";
import heroBg from "../../../images/heroBg.png";
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import hero2 from "../../../images/hero2.png";
import hero3 from "../../../images/hero3.png";
import hero4 from "../../../images/hero4.png";
import hero5 from "../../../images/hero5.png";

const Hero = () => {
  var settings = {
    // dots: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="hero">
      <div className="hero-content">
        
        <div className="hero-text">
          <div>
            <h1 className="hero-title">
              Empowering Nigeria's Small{" "}
              <span className="highlight">Businesses</span>
            </h1>
            <p className="hero-subtitle">
              Driving Success for Nigeria's Entrepreneurs
            </p>
            <div className="hero-cta">
              <Button
                variant="primary-1"
                link={"/services"}
                label="Learn More"
              />
              {/*<Button variant="secondary-1" label="Apply for a loan" />*/}
            </div>
          </div>
        </div>
        {/* */}
      </div>

      <div className="hero-images">
        <Slider {...settings} className="slider">
          <div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e04c3748dc8472f9dad087b34063a845ea9482dde0fad24c3815947166d7251c?apiKey=0dc91187d1fc4cb3931d3d7b27a4a892&"
              alt="Hero background"
              className="hero-image2"
            />
          </div>
          <div>
            <img
              loading="lazy"
              src={hero2}
              alt="Hero background"
              className="hero-image2"
            />
          </div>
          <div>
            <img
              loading="lazy"
              src={hero3}
              alt="Hero background"
              className="hero-image2"
            />
          </div>
          <div>
            <img
              loading="lazy"
              src={hero4}
              alt="Hero background"
              className="hero-image2"
            />
          </div>
          <div>
            <img
              loading="lazy"
              src={hero5}
              alt="Hero background"
              className="hero-image2"
            />
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Hero;
